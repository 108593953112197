import CarouselCaretLeft from "public/carousel-caret-left.svg";
import CarouselCaretRight from "public/carousel-caret-right.svg";
import { TeamSlides } from "components/pages/About/Team/PtcCarousel";
import { PTCSlide } from "components/pages/About/Team/TeamCard";
import ButtonV2 from "elements/ButtonV2";

export const ExpertsSection = ({
	noTitle = false,
	tracking,
}: {
	noTitle?: boolean;
	tracking?: { eventName?: string; data?: Record<string, any> };
}) => {
	return (
		<div id="landing-experts-section">
			{!noTitle && (
				<p className="landing-section-title">Featured property tax experts</p>
			)}
			<div id="about-team-section-slider-container">
				<div id="about-team-section-slider-nav-prev">
					<button>
						<CarouselCaretLeft />
					</button>
				</div>
				<TeamSlides SlideComponent={PTCSlide} />
				<div id="about-team-section-slider-nav-next">
					<button>
						<CarouselCaretRight />
					</button>
				</div>
			</div>
			<div id="about-team-section-slider-pagination" />
			<ButtonV2
				className="mx-auto landing-signup-today mt-4"
				size="large"
				toAppealFlow={{
					referrer: "Experts section Sign Up Today",
					separateTracker: tracking,
				}}>
				Sign Up Today
			</ButtonV2>
		</div>
	);
};
