import React, { FC, MouseEventHandler } from "react";
import Expand from "elements/ExpandButton";
import NextImage, { StaticImageData } from "next/image";
import InvertedPlusCircle from "public/inverted-plus-circle.svg";
import X from "public/x.svg";

export interface TeamSlideProps {
	name: string;
	bio: string;
	readMore: boolean;
	onClick: MouseEventHandler<HTMLDivElement>;
	imgSrc: StaticImageData;
}

const TeamSlide: FC<TeamSlideProps> = ({
	name,
	bio,
	onClick,
	readMore,
	imgSrc,
}) => {
	return (
		<div
			className="about-team-card"
			onClick={e => {
				e.preventDefault();
				e.stopPropagation();
				onClick(e);
			}}>
			<div className="about-team-img-container">
				<NextImage
					src={imgSrc}
					alt={name}
					className="about-team-img"
					fill
					sizes="100vw"
				/>
			</div>
			<h4>{name}</h4>
			<p className={(readMore ? "card-body-open" : "card-body") + " sm"}>
				{bio}
			</p>
			<Expand
				noSwitch
				defaultActive={readMore}
				className="about-team-read-more">
				{readMore ? "Read Less" : "Read More"}
			</Expand>
		</div>
	);
};

export const PTCSlide: FC<TeamSlideProps> = ({
	name,
	bio,
	onClick,
	readMore,
	imgSrc,
}) => {
	return (
		<div
			className="about-team-card ptc"
			onClick={e => {
				e.preventDefault();
				e.stopPropagation();
				onClick(e);
			}}>
			<div className="about-team-img-container">
				<NextImage
					src={imgSrc}
					alt={name}
					className="about-team-img"
					fill
					sizes="100vw"
				/>
			</div>
			<h4>{name}</h4>
			<p className={(readMore ? "card-body-open" : "card-body") + " sm"}>
				{bio}
			</p>

			<button className="button-learn-more">
				<p className="learn-more-text">Learn More</p>
				<InvertedPlusCircle />
			</button>

			<button className="ptc-close">
				Close <X />
			</button>
		</div>
	);
};

export default TeamSlide;
