import React, { FC, useEffect, useMemo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { useResponsiveContext } from "context/ResponsiveContext";
import ArrowLeft from "public/left-arrow.svg";
import ArrowRight from "public/right-arrow.svg";
import TeamSlide from "./TeamCard";
import "swiper/css/bundle";
import Kimberly from "public/about/team/Kimberly.jpeg";
import Sam from "public/about/team/Sam.jpeg";
import Christian from "public/about/team/Christian_Ezcurra.jpeg";
import Jordan from "public/about/team/jordan.jpeg";
import Francis from "public/about/team/francis.png";
import Sky from "public/about/team/sky.jpg";

const teamData = [
	{
		name: "Christian E.",
		bio: "Christian is an experienced property tax consultant based in Atlanta, GA. Christian has helped both commercial and residential property owners save millions of dollars each year. Christian previously consulted at Marvin F. Poer, studied at the Institute for Professionals in Taxation (IPT), and earned a bachelor's degree from the University of Mississippi where he majored in Managerial Finance.",
		imgSrc: Christian,
	},
	{
		name: "Kimberly S.",
		bio: "Kimberly is a Licensed Senior Property Tax Consultant based in Houston, TX. Over the past 13 years, Kimberly has successfully handled thousands of property tax appeals. She is also a licensed realtor in Texas, bringing a unique perspective to the team.",
		imgSrc: Kimberly,
	},
	{
		name: "Jordan R.",
		bio: "Jordan is a Certified General Appraiser in New York State, with a focus on property tax consultation in Long Island, New York. With a background as a commercial real estate appraiser spanning more than a decade, Jordan has successfully transitioned to specializing in tax grievances, leveraging his extensive experience to benefit property owners.",
		imgSrc: Jordan,
	},
	{
		name: "Sky P.",
		bio: "Based in the Dallas area, Sky is a licensed real estate salesperson and property tax consultant, holding an R.P.A. (Residential Professional Appraiser) license acquired during his seven years with the Collin Central Appraisal District. Sky has expertly managed tens of thousands of property tax appeals, securing significant tax reductions and savings for his clients.",
		imgSrc: Sky,
	},
	{
		name: "Francis K.",
		bio: "Francis is an expert in real property tax consulting, compliance, and valuation. A former licensed Texas Registered Professional Appraiser (RPA), Francis has amassed a diverse experience portfolio, including roles as a Staff Appraiser at the Dallas Central Appraisal District, a Senior Property Tax Associate at Grant Thornton LLP, a Valuation Analyst at Fannie Mae, and a Commercial Property Tax Consultant at Texas Tax Protest. His efforts have consistently resulted in substantial savings on property tax assessments for thousands of property owners and clients.",
		imgSrc: Francis,
	},
	{
		name: "Sam S.",
		bio: "Sam is based in Austin, TX and has successfully represented thousands of commercial properties spanning over 30 Texas counties and nationwide. Previously, Sam worked at the largest property tax consulting firm in the US, specializing in commercial property tax appeals.",
		imgSrc: Sam,
	},
];

export const TeamSlides: FC<{
	SlideComponent?: FC<any>;
}> = ({ SlideComponent = TeamSlide }) => {
	const [render, setRender] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalSlideIndex, setModalSlideIndex] = useState(0);

	useEffect(() => {
		// No SSR on carousel
		setRender(true);
	}, []);

	const TeamSlidesArr = useMemo(
		() =>
			teamData.map((props, i) => (
				<SwiperSlide key={props.name}>
					<SlideComponent
						{...props}
						readMore={false}
						onClick={() => {
							setShowModal(true);
							setModalSlideIndex(i);
						}}
					/>
				</SwiperSlide>
			)),
		[]
	);

	if (!render) return null;
	return (
		<>
			<Swiper
				spaceBetween={20}
				observer
				resizeObserver
				observeParents
				loop={true}
				style={{ padding: "50px 10px 12px 10px" }}
				breakpoints={{
					336: {
						slidesPerView: 1,
					},
					672: {
						slidesPerView: 2,
					},
					960: {
						slidesPerView: 3,
					},
				}}
				pagination={{
					el: "#about-team-section-slider-pagination",
					clickable: true,
					renderBullet: function (_, className) {
						return (
							'<span class="' +
							className +
							' about-team-section-pagination-bullet">' +
							"</span>"
						);
					},
				}}
				modules={[Pagination, Navigation]}
				navigation={{
					nextEl: "#about-team-section-slider-nav-next",
					prevEl: "#about-team-section-slider-nav-prev",
				}}>
				{TeamSlidesArr}
			</Swiper>
			{showModal && (
				<div
					id="about-team-read-more-modal"
					onClick={() => {
						setShowModal(false);
					}}>
					<SlideComponent
						{...teamData[modalSlideIndex]}
						readMore={true}
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							setShowModal(false);
						}}
					/>
				</div>
			)}
		</>
	);
};

const PtcCarousel = () => {
	return (
		<>
			{/* {!isDesktop ? (
        <> */}
			<div id="about-team-section-slider-container">
				<div id="about-team-section-slider-nav-prev">
					<ArrowLeft />
				</div>
				<TeamSlides />
				<div id="about-team-section-slider-nav-next">
					<ArrowRight />
				</div>
			</div>
			<div id="about-team-section-slider-pagination" />
			{/* </>
      ) : (
        <div id="about-team-section-slider-container">
          <TeamSlides  />
        </div>
      )} */}
		</>
	);
};

export default PtcCarousel;
